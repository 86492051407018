import { ECOM_VERSION } from './version';

export const environment = {
  production: true,
  version: ECOM_VERSION,
  apiHost: 'https://api.ecus.se/',
  statNrApiHost: 'https://api.ecus.se/',
  windowProxy: 'https://api.ecus.se/proxy',
  windowOrigin: 'https://ecom.ecus.se',
  signalR: {
    url: 'https://api.ecus.se/ecom'
  },
  timezone: 'Europe/Stockholm',
  defaultDateMoment: 'yyyy-MM-dd HH:mm',
  onlyDateMoment: 'yyyy-MM-dd',
  maxFileSize: 20971520, // 20 MiB
  sentry: {
    dsn:
      'https://942b76b925724465b124a4d82b01a595@o509176.ingest.sentry.io/5753892',
    environment: 'production',
    tracingOrigins: ['api.ecus.se'],
    tracesSampleRate: 0.2
  }
};
