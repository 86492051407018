import {
  TransportType,
  ClearanceCaseCustomerStatus,
  ClearanceCaseStatus,
  CpcDocumentMessageType
} from 'api/enums';
import { SelectItem } from 'shared/select-item.class';
import { Translations } from './translations';
import {
  UntypedFormArray,
  AbstractControl,
  UntypedFormGroup
} from '@angular/forms';
import { sortBy, padEnd } from 'lodash-es';
import { RegionDto } from 'api/models';
import { environment } from 'environments/environment';
import { format, parseISO } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import { InjectionToken } from '@angular/core';
import { AdminExportAction } from 'api/enums';
import { ErpArticleConnectionType } from 'api/financials/enums/erp-article-connection-type.enum';

export type SortOrder = 'asc' | 'desc';
export type NoSe = 'no' | 'se';

export const spinnerHtml = "<i class='fa fa-spinner fa-spin'></i>";

const seCurrency = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  currencyDisplay: 'code'
});

export function parseDate(input: Date | string): Date {
  if (!input || input.toString() === '') return null;
  if (input instanceof Date) return input;

  return parseISO(input);
}

export function handleDecimal(input: string | number): number {
  return parseFloat((input || '0').toString().replace(',', '.'));
}

export function formatCurrency(
  input: number,
  locale = 'sv-SE',
  currency = 'SEK',
  currencyDisplay: 'code' | 'symbol' | 'name' = 'code'
): string {
  if (locale !== 'sv-SE' || currency !== 'SEK' || currencyDisplay !== 'code')
    return input.toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: currencyDisplay
    });

  return seCurrency.format(input);
}

export function camelCaseToPascalCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toCamelCase(string: string) {
  return string[0].toLowerCase() + string.slice(1);
}

export class IconUtils {
  public static contentTypeIconClass(contentType: string): string {
    if (contentType.indexOf('image/') > -1) return 'fa fa-file-image-o';

    if (contentType === 'application/pdf') return 'fa fa-file-pdf-o';

    if (contentType.indexOf('zip') > -1) return 'fa fa-file-archive-o';

    if (
      contentType.indexOf('spreadsheetml') > -1 ||
      contentType.indexOf('ms-excel') > -1
    )
      return 'fa fa-file-excel-o';

    if (
      contentType.indexOf('wordprocessingml') > -1 ||
      contentType.indexOf('msword') > -1
    )
      return 'fa fa-file-word-o';

    if (
      contentType.indexOf('presentationml') > -1 ||
      contentType.indexOf('ms-powerpoint') > -1
    )
      return 'fa fa-file-powerpoint-o';

    return 'fa fa-file-o';
  }

  public static transportIcon(transportType: TransportType): string {
    switch (transportType) {
      case TransportType.Air:
        return 'fa fa-fw fa-plane';
      case TransportType.Car:
        return 'fa fa-fw fa-truck fa-flip-horizontal';
      case TransportType.Other:
        return 'fa fa-fw fa-question-circle-o';
      case TransportType.Ship:
        return 'fa fa-fw fa-ship';
      case TransportType.Train:
        return 'fa fa-fw fa-train';
    }
  }
}

export class CssUtils {
  public static customerCaseLogLabelClass(
    status: ClearanceCaseCustomerStatus
  ): string {
    switch (status) {
      case ClearanceCaseCustomerStatus.Cancelled:
        return 'label-cancelled-ecus';
      case ClearanceCaseCustomerStatus.Created:
        return 'label-created-ecus';
      case ClearanceCaseCustomerStatus.Registered:
        return 'label-registered-ecus';
      case ClearanceCaseCustomerStatus.Completed:
        return 'label-success-ecus';
      case ClearanceCaseCustomerStatus.Processing:
        return 'label-progress-ecus';
      case ClearanceCaseCustomerStatus.Waiting:
        return 'label-waiting-ecus';
    }
  }

  public static caseLogLabelClass(status: ClearanceCaseStatus): string {
    switch (status) {
      case ClearanceCaseStatus.Created:
        return 'label-created-ecus';
      case ClearanceCaseStatus.Registered:
        return 'label-registered-ecus';
      case ClearanceCaseStatus.Completed:
        return 'label-success-ecus';
      case ClearanceCaseStatus.Processed:
      case ClearanceCaseStatus.ProcessedAwaitingCustoms:
      case ClearanceCaseStatus.ProcessedCleared:
        return 'label-processed-ecus';
      case ClearanceCaseStatus.FirstLineProcessing:
      case ClearanceCaseStatus.ReadyForProcessing:
      case ClearanceCaseStatus.Processing:
        return 'label-progress-ecus';
      case ClearanceCaseStatus.WaitingForCustomer:
      case ClearanceCaseStatus.FirstLineWaitingForCustomer:
        return 'label-waiting-ecus';
      case ClearanceCaseStatus.Cancelled:
      case ClearanceCaseStatus.ProcessedFailedClearing:
        return 'label-cancelled-ecus';
      default:
        return 'label-unknown-ecus';
    }
  }

  public static caseStatusColorClass(status: ClearanceCaseStatus): string {
    switch (+status) {
      case ClearanceCaseStatus.Created:
      case ClearanceCaseStatus.Registered:
        return '';
      case ClearanceCaseStatus.FirstLineProcessing:
      case ClearanceCaseStatus.FirstLineWaitingForCustomer:
        return 'case-status-firstline';
      case ClearanceCaseStatus.ReadyForProcessing:
      case ClearanceCaseStatus.Processing:
      case ClearanceCaseStatus.WaitingForCustomer:
      case ClearanceCaseStatus.Processed:
        return 'case-status-case-manager';
      case ClearanceCaseStatus.ProcessedAwaitingCustoms:
        return 'case-status-customs-waiting';
      case ClearanceCaseStatus.ProcessedFailedClearing:
        return 'case-status-fail';
      case ClearanceCaseStatus.ProcessedCleared:
        return 'case-status-customs-cleared';
      case ClearanceCaseStatus.Completed:
        return 'case-status-completed';
      case ClearanceCaseStatus.Cancelled:
      default:
        return '';
    }
  }

  public static caseStatusBadgeClass(status: ClearanceCaseStatus): string {
    switch (+status) {
      case ClearanceCaseStatus.FirstLineProcessing:
      case ClearanceCaseStatus.FirstLineWaitingForCustomer:
      case ClearanceCaseStatus.ReadyForProcessing:
      case ClearanceCaseStatus.Processing:
      case ClearanceCaseStatus.WaitingForCustomer:
      case ClearanceCaseStatus.Processed:
      case ClearanceCaseStatus.ProcessedAwaitingCustoms:
        return 'uk-badge badge-yellow';
      case ClearanceCaseStatus.ProcessedAcceptedClearing:
        return 'uk-badge badge-blue';
      case ClearanceCaseStatus.ProcessedFailedClearing:
        return 'uk-badge badge-red';
      case ClearanceCaseStatus.ProcessedCleared:
        return 'uk-badge badge-green';
      case ClearanceCaseStatus.Completed:
        return 'uk-badge badge-grey';
      case ClearanceCaseStatus.Created:
      case ClearanceCaseStatus.Registered:
      case ClearanceCaseStatus.Cancelled:
      default:
        return '';
    }
  }
}

export class Utils {
  public static timeRegex = /([01]?[0-9]|2[0-3]):[0-5][0-9]/;
  public static dateRegex =
    /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

  private static _countryCodeItems: SelectItem[] = [];
  private static _nonISOCountryCodeItems: SelectItem[] = [];
  private static _ISOAndnonISOCountryCodeItems: SelectItem[] = [];

  public static friendlyTimeSpan(durationInMinutes: number): string {
    const days = Math.floor(durationInMinutes / 1440);
    durationInMinutes -= days * 1440;
    const hours = Math.floor(durationInMinutes / 60);
    durationInMinutes -= hours * 60;
    const minutes = durationInMinutes;

    const parts = [];
    if (days > 0) parts.push(`${days}d`);

    if (hours > 0) parts.push(`${hours}h`);

    if (minutes > 0) parts.push(`${minutes}m`);

    return parts.join(' ');
  }

  public static padLeft(
    value: number | string,
    length: number,
    char: string = ' '
  ): string {
    let s = value + '';

    while (s.length < length) s = char + s;

    return s;
  }

  static formatDate(input: Date | string): string {
    if (typeof input === 'string') input = new Date(Date.parse(input));

    return `${input.getUTCFullYear()}-${(input.getUTCMonth() + 1)
      .toString(10)
      .padStart(2, '0')}-${input.getUTCDate().toString(10).padStart(2, '0')}`;
  }

  public static get countryCodeItems(): SelectItem[] {
    if (this._countryCodeItems.length === 0)
      for (const code in CountryCodes)
        if (isNaN(Number(code)))
          this._countryCodeItems.push(
            new SelectItem(
              code.toString(),
              `[${code.toString()}] ${
                Translations.CountryCodes[code.toString()]
              }`,
              null,
              code
            )
          );

    return this._countryCodeItems;
  }

  public static get nonIsoCountryCodeItems(): SelectItem[] {
    if (this._nonISOCountryCodeItems.length === 0)
      for (const code in NonISOCountryCodes) {
        if (isNaN(Number(code))) {
          this._nonISOCountryCodeItems.push(
            new SelectItem(
              code.toString(),
              `[${code.toString()}] ${
                Translations.NonIsoCountryCodes[code.toString()]
              }`,
              null,
              code
            )
          );
        }
      }

    return this._nonISOCountryCodeItems;
  }

  public static get isoAndNonIsoCountryCodeItems(): SelectItem[] {
    if (this._ISOAndnonISOCountryCodeItems.length === 0) {
      const items = this.countryCodeItems.concat(this.nonIsoCountryCodeItems);
      items.sort((a, b) => a.value.localeCompare(b.value));
      this._ISOAndnonISOCountryCodeItems = items;
    }
    return this._ISOAndnonISOCountryCodeItems;
  }
}

export function markSearchResult(haystack: string, needle: string): string {
  if (
    needle == null ||
    needle === '' ||
    haystack.toLowerCase().indexOf(needle.toLowerCase()) === -1
  )
    return haystack;

  return haystack.replace(new RegExp(`(${needle})`, 'ig'), '<mark>$1</mark>');
}

export function formatPhoneNumber(input: string, countryCode: string): string {
  // FIXME: This cannot possibly cause any weird errors anywhere

  // Remove initial 0 if present
  input = input.replace(/^0/, '');

  if (countryCode === 'SE') input = `+46${input}`;
  if (countryCode === 'NO') input = `+47${input}`;
  if (countryCode === 'DK') input = `+44${input}`;

  return input;
}

const containerValues = {
  A: 10,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 23,
  M: 24,
  N: 25,
  O: 26,
  P: 27,
  Q: 28,
  R: 29,
  S: 30,
  T: 31,
  U: 32,
  V: 34,
  W: 35,
  X: 36,
  Y: 37,
  Z: 38,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9
};
export function generateContainerCheckDigit(input: string): number {
  input = (input || '').replace(/[^A-Za-z0-9]/g, '').toUpperCase();
  if (input.length < 10 || input.length > 11) return null;
  if (!input.match(/([A-Z]){4}([0-9]){6,7}/)) return null;

  const ownerCode = input.substr(0, 3);
  const equipmentCategory = input.substr(3, 1);
  if (
    equipmentCategory !== 'U' &&
    equipmentCategory !== 'J' &&
    equipmentCategory !== 'Z'
  )
    return null;

  let sum = 0;
  for (let i = 0; i < 10; i++) {
    const char = input[i];
    sum += containerValues[char] * Math.pow(2, i);
  }

  const digit = sum % 11;
  return digit === 10 ? 0 : digit;
}

export function isContainerNumberValid(input: string): boolean {
  input = (input || '').replace(/[^A-Za-z0-9]/g, '').toUpperCase();
  if (input.length !== 11) return false;
  if (!input.match(/([A-Z]){4}([0-9]){7}/)) return false;

  const expected = parseInt(input[10], 10);
  const actual = generateContainerCheckDigit(input);
  return actual && actual === expected;
}

export function firstOrDefault(
  input: UntypedFormArray,
  comparator: (item: AbstractControl) => boolean,
  builder: () => UntypedFormGroup
) {
  const len = input.length;
  for (let i = 0; i < len; i++) {
    const row = input.at(i);
    if (comparator(row)) return row;
  }

  const newRow = builder();
  input.push(newRow);

  return newRow;
}

export function where(
  input: UntypedFormArray,
  comparator: (item: AbstractControl) => boolean
): UntypedFormGroup[] {
  const result: UntypedFormGroup[] = [];
  const len = input.length;

  for (let i = 0; i < len; i++) {
    const row = input.at(i);
    if (comparator(row)) result.push(row as UntypedFormGroup);
  }

  return result;
}

export function findIndex(
  input: UntypedFormArray,
  comparator: (item: AbstractControl) => boolean
): number {
  const len = input.length;

  for (let i = 0; i < len; i++) {
    const row = input.at(i);
    if (comparator(row)) return i;
  }

  return -1;
}

export enum CountryCodes {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ESCN,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GH,
  GI,
  GL,
  GM,
  GN,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MD,
  ME,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PS,
  PT,
  PW,
  PY,
  QA,
  QP,
  QQ,
  QR,
  QS,
  QU,
  QV,
  QW,
  QX,
  QY,
  QZ,
  RO,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XC,
  XK,
  XL,
  XS,
  YE,
  ZA,
  ZM,
  ZW
}

export const EESCountryCodes = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'DE',
  'EE',
  'GR',
  'ES',
  'FR',
  'IE',
  'IT',
  'HR',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'GB'
];

export enum NonISOCountryCodes {
  EØS
}

export const EFTACountryCodes = ['CH', 'NO', 'IS', 'LI'];

export const AlmostEUCountryCodes = ['AX', 'FI02', 'FO', 'ESCN'];

export function generateArticleNumber(length: number): string {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function partitionFiles(files: any[]): any[][] {
  const filePartitions = [[], [], []];

  const length = files.length / 3;
  const sorted = sortBy(files, file => file.fileName);
  let chunk = 3;
  for (let i = 0, j = sorted.length; i < j; i += length) {
    filePartitions[--chunk] = sorted.slice(i, i + length);
  }

  return filePartitions;
}

export function sortRegions(r1: RegionDto, r2: RegionDto): number {
  if (r1.number === 99 || r2.number === 99) return -1;

  const n1 = r1.name.toLowerCase();
  const n2 = r2.name.toLowerCase();
  return n1 === n2 ? 0 : n1 > n2 ? 1 : -1;
}

export function formattedDate(
  input: string | number | Date,
  onlyDate: boolean = false
): string {
  return format(
    toZonedTime(input, environment.timezone),
    onlyDate ? environment.onlyDateMoment : environment.defaultDateMoment
  );
}

export function utcFormattedDate(
  input: string | number | Date,
  onlyDate: boolean = false
): string {
  return format(
    fromZonedTime(input, environment.timezone),
    onlyDate ? environment.onlyDateMoment : environment.defaultDateMoment
  );
}

export function roundNumber(number: number, decimalPlaces: number) {
  let roundedNumber =
    Math.round(number * Math.pow(10, decimalPlaces)) /
    Math.pow(10, decimalPlaces);
  if (Object.is(roundedNumber, -0)) {
    roundedNumber = 0;
  }
  return roundedNumber;
}

export function setEurEucCurrency(control: AbstractControl, useEUC: boolean) {
  if (control?.value == 'EUR' && useEUC) {
    control.setValue('EUC');
    return;
  }
  if (control?.value == 'EUC' && !useEUC) {
    control.setValue('EUR');
    return;
  }
}

export function translateMessageType(
  input: CpcDocumentMessageType | string
): string {
  switch (input) {
    case 0:
    case '0':
    case CpcDocumentMessageType.NoExp:
      return 'NO-EXP';
    case 1:
    case '1':
    case CpcDocumentMessageType.NoImp:
      return 'NO-IMP';
    case 2:
    case '2':
    case CpcDocumentMessageType.SeExp:
      return 'SE-EXP';
    case 3:
    case '3':
    case CpcDocumentMessageType.SeExp:
      return 'SE-IMP';
  }

  return 'b0rk';
}

export function translateAdminExportAction(
  input: AdminExportAction | string
): string {
  switch (input) {
    case 0:
    case '0':
    case AdminExportAction.ChangedStatus:
      return 'Status ändrad';
    case 1:
    case '1':
    case AdminExportAction.Canceled:
      return 'Raderad';
  }

  return 'Skickade till MC';
}

export function translateErpArticleConnectionType(
  input: ErpArticleConnectionType | string
): string {
  switch (input) {
    case ErpArticleConnectionType.SeExport:
      return 'SE export';
    case ErpArticleConnectionType.SeImport:
      return 'SE import';
    case ErpArticleConnectionType.NoExport:
      return 'NO export';
    case ErpArticleConnectionType.NoImport:
      return 'NO import';
    case ErpArticleConnectionType.ExtraCaseRows:
      return 'Extra varulinje';
    case ErpArticleConnectionType.ExtraCaseTime:
      return 'Extra tid';
    case ErpArticleConnectionType.Eur1:
      return 'EUR1';
    case ErpArticleConnectionType.RegistrationCard:
      return 'Registreringskort';
    case ErpArticleConnectionType.Delivery:
      return 'Utlämning';
    case ErpArticleConnectionType.CustomsFee:
      return 'Tullavgift';
    case ErpArticleConnectionType.OutlayImportVAT:
      return 'Utlägg införselmoms';
    case ErpArticleConnectionType.HandlingFee:
      return 'Hanteringsavgift';
    default:
      return '';
  }
}

export declare const MAT_INPUT_VALUE_ACCESSOR: InjectionToken<{
  value: any;
}>;
