import {
  CaseDocumentType,
  ClearanceCaseStatus,
  ImportExportCountry
} from 'api/enums';
import { ClearanceCaseSummaryDto } from 'api/models';
import { EnumUtils } from 'app/enum-utils';
import { Translations } from 'app/translations';
import { CssUtils, IconUtils } from 'app/utils';
import {
  getCustomerStatusClass,
  getCustomerStatusIconName,
  getCustomerStatusText,
  getStatusIconName
} from 'case-data/utils/case-status-utils';

export class OverviewClearanceCase extends ClearanceCaseSummaryDto {
  importText: string;
  exportText: string;
  documentLetter: string;
  isDocument: boolean;
  invoiceRecipientsText: string;

  constructor(
    clearanceCase: ClearanceCaseSummaryDto,
    private readonly isCustomer: boolean
  ) {
    super();
    Object.assign(this, clearanceCase);

    this.importText = OverviewClearanceCase.getImportExportFlagIcon(
      this.importCountry
    );
    this.exportText = OverviewClearanceCase.getImportExportFlagIcon(
      this.exportCountry
    );
    this.documentLetter = OverviewClearanceCase.getDocumentLetter(
      this.isCaseDocument,
      this.caseDocumentTypes
    );
    if (this.invoiceRecipients) {
      this.invoiceRecipientsText = this.invoiceRecipients
        .map(ir => ir.name)
        .join(', ');
    }

    this.isDocument = !!this.documentLetter;
  }

  get documentType() {
    return this.isCaseDocument
      ? EnumUtils.toDocumentType(this.caseDocumentTypes.find(d => d))
      : null;
  }

  get statusText(): string {
    if (this.isCustomer) {
      return getCustomerStatusText(this.customerStatus);
    } else {
      if (
        this.status === ClearanceCaseStatus.ProcessedAcceptedClearing &&
        this.isNorwegianCase() &&
        !this.isSwedishCase
      ) {
        return Translations.ClearanceCaseLogEntry['ProcessedReceivedClearing'];
      }

      return Translations.ClearanceCaseLogEntry[
        ClearanceCaseStatus[this.status]
      ];
    }
  }

  get statusIcon(): string {
    const iconName = this.getStatusIconName();
    return iconName !== '' ? `fa fa-${iconName}` : '';
  }

  get statusClass(): string {
    return this.isCustomer
      ? getCustomerStatusClass(this.customerStatus)
      : CssUtils.caseStatusBadgeClass(this.status);
  }
  getDirectionalStatusIcon(status: ClearanceCaseStatus): string {
    const iconName = getStatusIconName(status);
    return iconName !== '' ? `fa fa-${iconName}` : '';
  }

  getDirectionalStatusClass(status: ClearanceCaseStatus): string {
    return CssUtils.caseStatusBadgeClass(status);
  }

  getDocumentStatusText(status: ClearanceCaseStatus): string {
    if (!status) return '';
    return Translations.ClearanceCaseLogEntry[ClearanceCaseStatus[status]];
  }

  get contactName(): string {
    return this.contact != null ? this.contact.name : '';
  }

  private getStatusIconName(): string {
    if (this.isCustomer) {
      return getCustomerStatusIconName(this.customerStatus);
    } else {
      switch (this.status) {
        case ClearanceCaseStatus.Created:
        case ClearanceCaseStatus.Registered:
        case ClearanceCaseStatus.ReadyForProcessing:
          return '';
      }
      return getStatusIconName(this.status);
    }
  }

  private static getImportExportFlagIcon(country: ImportExportCountry): string {
    switch (+country) {
      case ImportExportCountry.Sweden:
        return 'flag-icon flag-icon-se';
      case ImportExportCountry.Norway:
        return 'flag-icon flag-icon-no';
      default:
        return '';
    }
  }

  private static getDocumentLetter(
    isCaseDocument: boolean,
    documentTypes: CaseDocumentType[]
  ): string {
    if (isCaseDocument) {
      var documentType = documentTypes.find(d => d);
      switch (documentType) {
        case CaseDocumentType.Delivery:
          return 'U';
        case CaseDocumentType.Eur1:
          return 'E';
        case CaseDocumentType.RegistrationCard:
          return 'R';
        default:
          return null;
      }
    }
    return null;
  }

  public get transportIcon(): string {
    return IconUtils.transportIcon(this.transportType);
  }

  public get initials(): string {
    return this.caseManager ? this.caseManager.initials : '';
  }

  public get caseManagerName(): string {
    return this.caseManager ? this.caseManager.name : '';
  }

  private isNorwegianCase(): boolean {
    return (
      this.importCountry === ImportExportCountry.Norway ||
      this.exportCountry === ImportExportCountry.Norway
    );
  }

  private isSwedishCase(): boolean {
    return (
      this.importCountry === ImportExportCountry.Sweden ||
      this.importCountry === ImportExportCountry.SwedenEu ||
      this.exportCountry === ImportExportCountry.Sweden ||
      this.exportCountry === ImportExportCountry.SwedenEu
    );
  }
}
