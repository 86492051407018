import {
  CustomerCompanyCaseDifficulty,
  CaseDifficulties,
  CustomerCompanyCaseLength,
  CaseLengths,
  CaseDocumentType,
  DocumentType
} from 'api/enums';
import { CaseOverviewType } from 'cases-overview/models/case-overview-type.enum';
import { CaseOverviewViewMode } from 'cases-overview/models/case-overview-view-mode.enum';
import { CaseRowsViewMode } from 'cases/models';
import { ColumnSettingsViewMode } from 'api/enums';

export class EnumUtils {
  public static hasFlag(input: number, flag: number): boolean {
    return (input & flag) === flag;
  }

  public static ToCaseDifficulties(
    difficulty: CustomerCompanyCaseDifficulty | string
  ): CaseDifficulties {
    if (typeof difficulty === 'string' && !isNaN(parseInt(difficulty, 10))) {
      difficulty =
        CustomerCompanyCaseDifficulty[
          CustomerCompanyCaseDifficulty[parseInt(difficulty, 10)]
        ];
    } else if (typeof difficulty === 'string') {
      difficulty = CustomerCompanyCaseDifficulty[difficulty];
    }

    switch (difficulty) {
      case CustomerCompanyCaseDifficulty.Easy:
        return CaseDifficulties.Easy;
      case CustomerCompanyCaseDifficulty.Difficult:
        return CaseDifficulties.Difficult;
      default:
        return null;
    }
  }

  public static ToCustomerCompanyCaseDifficulty(
    difficulty: CaseDifficulties | string
  ): CustomerCompanyCaseDifficulty {
    if (typeof difficulty === 'string' && !isNaN(parseInt(difficulty, 10))) {
      difficulty = CaseDifficulties[CaseDifficulties[parseInt(difficulty, 10)]];
    } else if (typeof difficulty === 'string') {
      difficulty = CaseDifficulties[difficulty];
    }

    switch (difficulty) {
      case CaseDifficulties.Easy:
        return CustomerCompanyCaseDifficulty.Easy;
      case CaseDifficulties.Difficult:
        return CustomerCompanyCaseDifficulty.Difficult;
      default:
        return null;
    }
  }

  public static ToCaseLengths(
    length: CustomerCompanyCaseLength | string
  ): CaseLengths {
    if (typeof length === 'string' && !isNaN(parseInt(length, 10))) {
      length =
        CustomerCompanyCaseLength[
          CustomerCompanyCaseLength[parseInt(length, 10)]
        ];
    } else if (typeof length === 'string') {
      length = CustomerCompanyCaseLength[length];
    }

    switch (length) {
      case CustomerCompanyCaseLength.Short:
        return CaseLengths.Short;
      case CustomerCompanyCaseLength.Long:
        return CaseLengths.Long;
      default:
        return null;
    }
  }

  public static ToCustomerCompanyCaseLength(
    length: CaseLengths | string
  ): CustomerCompanyCaseLength {
    if (typeof length === 'string' && !isNaN(parseInt(length, 10))) {
      length = CaseLengths[CaseLengths[parseInt(length, 10)]];
    } else if (typeof length === 'string') {
      length = CaseLengths[length];
    }

    switch (length) {
      case CaseLengths.Short:
        return CustomerCompanyCaseLength.Short;
      case CaseLengths.Long:
        return CustomerCompanyCaseLength.Long;
      default:
        return null;
    }
  }

  public static toCaseRowsViewMode(viewMode: ColumnSettingsViewMode) {
    switch (viewMode) {
      case ColumnSettingsViewMode.Basic:
        return CaseRowsViewMode.Basic;
      case ColumnSettingsViewMode.Advanced:
        return CaseRowsViewMode.Advanced;
      case ColumnSettingsViewMode.Custom:
        return CaseRowsViewMode.Custom;
      case ColumnSettingsViewMode.Detailed:
        return CaseRowsViewMode.Detailed;
      default:
        return CaseRowsViewMode.Basic;
    }
  }

  public static caseRowToColumnSettingsViewMode(viewMode: CaseRowsViewMode) {
    switch (viewMode) {
      case CaseRowsViewMode.Basic:
        return ColumnSettingsViewMode.Basic;
      case CaseRowsViewMode.Advanced:
        return ColumnSettingsViewMode.Advanced;
      case CaseRowsViewMode.Custom:
        return ColumnSettingsViewMode.Custom;
      case CaseRowsViewMode.Detailed:
        return ColumnSettingsViewMode.Detailed;
      default:
        return ColumnSettingsViewMode.Basic;
    }
  }

  public static caseOverviewToColumnSettingsViewMode(
    viewMode: CaseOverviewViewMode
  ) {
    switch (viewMode) {
      case CaseOverviewViewMode.Basic:
      case CaseOverviewViewMode.FinanceBasic:
        return ColumnSettingsViewMode.Basic;
      case CaseOverviewViewMode.Advanced:
      case CaseOverviewViewMode.FinanceAdvanced:
        return ColumnSettingsViewMode.Advanced;
      case CaseOverviewViewMode.Custom:
        return ColumnSettingsViewMode.Custom;
      default:
        return ColumnSettingsViewMode.Basic;
    }
  }

  public static toCaseOverviewViewMode(
    viewMode: ColumnSettingsViewMode,
    type: CaseOverviewType
  ): CaseOverviewViewMode {
    switch (type) {
      case CaseOverviewType.Cases:
        switch (viewMode) {
          case ColumnSettingsViewMode.Basic:
            return CaseOverviewViewMode.Basic;
          case ColumnSettingsViewMode.Advanced:
            return CaseOverviewViewMode.Advanced;
          case ColumnSettingsViewMode.Custom:
            return CaseOverviewViewMode.Custom;
          default:
            return CaseOverviewViewMode.Basic;
        }
      case CaseOverviewType.Finance:
        switch (viewMode) {
          case ColumnSettingsViewMode.Basic:
            return CaseOverviewViewMode.FinanceBasic;
          case ColumnSettingsViewMode.Advanced:
            return CaseOverviewViewMode.FinanceAdvanced;
          case ColumnSettingsViewMode.Custom:
            return CaseOverviewViewMode.Custom;
          default:
            return CaseOverviewViewMode.FinanceBasic;
        }
      default:
        return CaseOverviewViewMode.Custom;
    }
  }

  public static toDocumentType(caseDocumentType: CaseDocumentType) {
    switch (caseDocumentType) {
      case CaseDocumentType.Delivery:
        return DocumentType.Delivery;
      case CaseDocumentType.Eur1:
        return DocumentType.Eur1;
      case CaseDocumentType.RegistrationCard:
        return DocumentType.RegistrationCard;
      default:
        return DocumentType.WorkDocument;
    }
  }
}
