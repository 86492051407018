import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AtLeastOneClearanceTypeDirective } from 'app/shared/at-least-one-clearance-type.directive';
import { DifferentImportExportCountryDirective } from 'app/shared/different-import-export-country.directive';
import {
  EcomNgTranscludeDirective,
  EcomTabDirective,
  EcomTabHeadingDirective,
  EcomTabsetComponent,
  EcomTabsetConfig
} from 'app/shared/tabs';
import { TenantService } from 'app/tenants/services/tenant.service';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxMdModule } from 'ngx-md';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { DirectAccessQuickEntryComponent } from 'shared/forms/direct-access-quickentry.component';
import { GravatarComponent } from 'shared/gravatar.component';
import { BusyBackdropComponent } from './busy/ecom-busy-backdrop.component';
import { BusyComponent } from './busy/ecom-busy.component';
import { BusyConfig, BUSY_CONFIG_DEFAULTS } from './busy/ecom-busy.config';
import { BusyDirective } from './busy/ecom-busy.directive';
import { BusyService } from './busy/ecom-busy.service';
import { DATEPICKER_LANG, DATEPICKER_LOCALE } from './calendar.sv';
import { CaseDocumentSortPipe } from './case-document-sort.pipe';
import { CaseRowTaxSortPipe } from './case-row-tax-sort.pipe';
import { CheckFlagComponent } from './check-flag.component';
import { CommentHistoryModal } from './components/comment-icon/comment-history-modal/comment-history.modal';
import { CommentIconComponent } from './components/comment-icon/comment-icon.component';
import { ShowCommentPopover } from './components/comment-icon/show-comment-popover/show-comment.popover';
import { EditActorDataComponent } from './components/edit-actor-data/edit-actor-data.component';
import { EcomErrorToasterComponent } from './components/error-toaster/error-toaster.component';
import { InitialsAvatarComponent } from './components/initials-avatar/initials-avatar.component';
import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog.component';
import { CurrencyPipe } from './currency.pipe';
import { DefaultHtmlPipe, DefaultPipe } from './default.pipe';
import { EcomTemplateDirective } from './ecom-template.directive';
import { EcomTranslationHttpLoader } from './ecom-translation-http-loader';
import { DelayedInputComponent } from './forms/delayed-input.component';
import { InputFormatDirective } from './forms/input-format.directive';
import { PrimeNGKeyboardDirective } from './forms/p-keyboard.directive';
import { QuickEntryColumnComponent } from './forms/quickentry-column.component';
import { QuickEntryComponent } from './forms/quickentry.component';
import { UikitFormElementComponent } from './forms/uikit-form-element.component';
import { UiKitFormComponent } from './forms/uikit-form.component';
import { Hotkeys } from './hotkeys/hotkeys.service';
import { BaseModalLayoutComponent } from './modal/base-modal-layout.component';
import { IOModalService } from './modal/io-modal.service';
import { PaginationTableComponent } from './pagination/pagination-table.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationConfig } from './pagination/pagination.config';
import { ConfirmPopover } from './popover/confirm.popover';
import { PopoverService } from './popover/popover.service';
import {
  PackageCategoriesService,
  ActorsService,
  SettingsService
} from './services';
import { AdministrativeUnitsService } from './services/administrative-units.service';
import { ArticlesService } from './services/articles.service';
import { PresenceService } from './services/presence.service';
import { SharedDataRepository } from './services/repositories/shared-data.repository';
import { SharedFinancialsService } from './services/shared-financials.service';
import { SubmitButtonComponent } from './submit-button.component';
import { EcomUploadPanelComponent } from './upload-panel/upload-panel.component';
import { WindowService } from './window.service';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { FormTooltipIconComponent } from 'shared/forms/form-tooltip-icon.component';
import { EcomFormElementDropdownComponent } from './forms/ecom-form-element-dropdown.component';
import { EcomFormElementTextComponent } from './forms/ecom-form-element-text.component';
import { EcomFormElementTextWithAddonComponent } from './forms/ecom-form-element-text-with-addon.component';
import { CaseRowService } from './services/case-row.service';
import { PaginationRowService } from './services/pagination-row-service';
import { ToastrModule } from 'ngx-toastr';
import { RequireAdminDirective, RequireRolesDirective } from './auth';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMdModule,
    TranslateModule,
    MenuModule,
    MultiSelectModule,
    DropdownModule,
    KeyFilterModule,
    DragDropModule,
    AutoCompleteModule,
    TreeModule,
    CalendarModule,
    SelectButtonModule,
    ListboxModule,
    InputSwitchModule,
    FieldsetModule,
    OverlayPanelModule,
    ChipsModule,
    TooltipModule,
    TableModule,
    DataViewModule,
    ToastrModule.forRoot(),
    ConfirmDialogModule
  ],
  declarations: [
    CurrencyPipe,
    DifferentImportExportCountryDirective,
    AtLeastOneClearanceTypeDirective,
    EcomNgTranscludeDirective,
    EcomTabDirective,
    EcomTabsetComponent,
    EcomTabHeadingDirective,
    EcomTemplateDirective,
    BaseModalLayoutComponent,
    EcomUploadPanelComponent,
    DefaultPipe,
    DefaultHtmlPipe,
    ConfirmationDialogComponent,
    GravatarComponent,
    PaginationComponent,
    PaginationTableComponent,
    CheckFlagComponent,
    SubmitButtonComponent,
    CaseDocumentSortPipe,
    CaseRowTaxSortPipe,
    UikitFormElementComponent,
    EcomFormElementDropdownComponent,
    EcomFormElementTextComponent,
    EcomFormElementTextWithAddonComponent,
    QuickEntryComponent,
    QuickEntryColumnComponent,
    UiKitFormComponent,
    DelayedInputComponent,
    InputFormatDirective,
    BusyDirective,
    PrimeNGKeyboardDirective,
    BusyComponent,
    BusyBackdropComponent,
    ConfirmPopover,
    DirectAccessQuickEntryComponent,
    EditActorDataComponent,
    CommentIconComponent,
    ShowCommentPopover,
    CommentHistoryModal,
    InitialsAvatarComponent,
    EcomErrorToasterComponent,
    FormTooltipIconComponent,
    RequireRolesDirective,
    RequireAdminDirective
  ],
  exports: [
    CurrencyPipe,
    DifferentImportExportCountryDirective,
    AtLeastOneClearanceTypeDirective,
    EcomNgTranscludeDirective,
    EcomTabDirective,
    EcomTabsetComponent,
    EcomTabHeadingDirective,
    EcomTemplateDirective,
    EcomUploadPanelComponent,
    DefaultPipe,
    DefaultHtmlPipe,
    BaseModalLayoutComponent,
    ConfirmationDialogComponent,
    GravatarComponent,
    PaginationComponent,
    PaginationTableComponent,
    CheckFlagComponent,
    SubmitButtonComponent,
    CaseDocumentSortPipe,
    CaseRowTaxSortPipe,
    UikitFormElementComponent,
    EcomFormElementDropdownComponent,
    EcomFormElementTextComponent,
    EcomFormElementTextWithAddonComponent,
    QuickEntryComponent,
    QuickEntryColumnComponent,
    UiKitFormComponent,
    DelayedInputComponent,
    EcomErrorToasterComponent,
    InputFormatDirective,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CalendarModule,
    FileUploadModule,
    NgxMdModule,
    TranslateModule,
    MenuModule,
    MultiSelectModule,
    DropdownModule,
    KeyFilterModule,
    DragDropModule,
    AutoCompleteModule,
    TreeModule,
    SelectButtonModule,
    ListboxModule,
    InputSwitchModule,
    OverlayPanelModule,
    FieldsetModule,
    ChipsModule,
    TabViewModule,
    TableModule,
    DataViewModule,
    TieredMenuModule,
    PanelModule,

    BusyDirective,
    PrimeNGKeyboardDirective,
    DirectAccessQuickEntryComponent,
    EditActorDataComponent,
    CommentIconComponent,
    InitialsAvatarComponent,
    FormTooltipIconComponent,
    RequireRolesDirective,
    RequireAdminDirective
  ]
})
export class SharedModule {
  public static forRoot(
    config: BusyConfig = null
  ): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        EcomTabsetConfig,
        PaginationConfig,
        PackageCategoriesService,
        ActorsService,
        AdministrativeUnitsService,
        WindowService,
        IOModalService,
        PopoverService,
        ArticlesService,
        BusyService,
        TenantService,
        PresenceService,
        SettingsService,
        SharedFinancialsService,
        SharedDataRepository,
        CaseRowService,
        PaginationRowService,
        Hotkeys,
        ConfirmationService,
        { provide: BusyConfig, useValue: config || BUSY_CONFIG_DEFAULTS },
        { provide: DATEPICKER_LANG, useValue: DATEPICKER_LOCALE }
      ]
    };
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new EcomTranslationHttpLoader(http);
}
